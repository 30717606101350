<template>
  <div class="flip-container">
    <div class="login-screen">
      <span class="main-logo" :style="{ backgroundImage: `url(${config.SITE_LOGO_URL})` }"></span>
      <div class="login-block row middle-xs">
        <span
          class="loginimg"
          :style="{ backgroundImage: `url(${config.LOGIN_PAGE_IMAGE_URL})` }"
        ></span>
        <div class="login-info">
          <span class="ftitle">Reset Password</span>
          <label class="flabel">New Password</label>
          <k-text-input
            class="login-input"
            :required="true"
            :default-focus="true"
            type="password"
            @change="onChangePassword"
            @keyup="onKeyUp"
            @validation="onValidation"
          />
          <label class="flabel">Confirm Password</label>
          <k-text-input
            class="login-input"
            :required="true"
            type="password"
            @change="onChangeConfirmPassword"
            @keyup="onKeyUp"
            @validation="onValidation"
          />
          <k-button :size="4" label="Set Password" @click="onClickSetPassword" />
        </div>
        <div v-if="error" class="error">Error: {{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import { isBlank } from 'adready-api/helpers/common';
import validationsMixin from 'adready-vue/components/mixins/validations-mixin';
import config from '~/config';

export default {
  name: 'ResetPassword',

  mixins: [validationsMixin.events],

  props: {
    err: {
      required: false,
      type: String,
      default: undefined,
    },
    userId: {
      required: true,
      type: String,
    },
    token: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      password: null,
      confirmPassword: null,
      errorMsg: null,
      appId: null,
      mode: null,
      config,
    };
  },

  computed: {
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      if (!isBlank(this.err)) {
        return this.err;
      }
      return null;
    },
  },

  created() {
    this.appId = this.$route.query.app;
    this.mode = this.$route.query.mode;
    if (!this.userId || isBlank(this.token)) {
      this.$router.replace({ name: 'Login' });
    }
  },

  methods: {
    isFlipPlus() {
      return this.mode && this.mode === 'fp';
    },

    onChangePassword(val) {
      // update text directly
      this.password = val;
    },

    onChangeConfirmPassword(val) {
      // update text directly
      this.confirmPassword = val;
    },

    onKeyUp(val, e) {
      if (e.key && e.key.includes('Enter')) {
        this.onClickSetPassword();
      }
    },

    onClickSetPassword() {
      if (this.password !== this.confirmPassword) {
        this.errorMsg = 'Passwords do not match';
        return;
      }

      this.errorMsg = null;

      axios
        .post(`${config.ADREADY_URL}/api/auth/reset_password?app=${this.appId}`, {
          userId: this.userId,
          token: this.token,
          password: this.password,
        })
        .then(() => {
          window.location = this.isFlipPlus() ? config.FLIP_PLUS_URL : config.FLIP_URL;
        })
        .catch((error) => {
          this.errorMsg = 'failed to create password';
          // eslint-disable-next-line no-console
          console.error(this.errorMsg, error);
          if (this.$sentry) {
            if (error._reported !== true) {
              this.$sentry.captureException(error);
              error._reported = true;
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.flip-container {
  background-color: #131619;
  width: 100vw !important;
  height: 100vh;
  margin-bottom: -40px;
}
::v-deep .form-input .form-input-field {
  width: 100%;
  padding: 15px 20px;
  box-sizing: border-box;
  font-size: 12px;
  border: 1px solid #dadada !important;
  background-color: #fff !important;
  color: #000 !important;
}
::v-deep .k-btn.btn-size-4 {
  padding: 0 !important;
  background-color: var(--defaultprimarycolor) !important;
  font-size: 14px !important;
  // padding: 20px 18px !important;
  border-radius: 0 !important;
  border: 0px;
  cursor: pointer;
  appearance: none;
  color: #fff !important;
  text-transform: uppercase;
  letter-spacing: 0.15em !important;
  line-height: 1.2em;
  display: inline-block;
  font-weight: 400 !important;
  transition: all 0.6s ease;
  text-align: center;
  min-width: 214px !important;
  user-select: none;
  margin-top: 25px;
  span.label {
    padding: 14px 22px !important;
  }
  &:hover {
    background-color: #424242 !important;
    color: #fff !important;
  }
}
.ftitle {
  padding-bottom: 30px;
}
</style>
